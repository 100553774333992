$primary-color: #e54545;
$secondary-color: #952f2f;
$white-color: #ffffff;
$theme-color: #191919;

body{
  background: $theme-color;
}
.app-body .main{
  color: $white-color;
}
a {
  text-decoration: none;
  color: #952f2f !important;
  transition: 0.4s;
	cursor: pointer;
}
a:hover {
  text-decoration: none;
  color: #952f2f !important;
  transition: 0.4s;
	cursor: pointer;
}
.btn-secondary {
		border-radius: 0px;
		letter-spacing: 0px;
    color: $white-color;
    background-color: $primary-color;
    border-color: $primary-color;

		span{
			padding-left: 10px;
		}
    &:hover {
        color: $white-color;
        background-color: $primary-color;
        border-color: $primary-color;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: none;
    }
    &.focus, &:focus {
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
        color: $white-color;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}
.row{
  padding-bottom: 20px;
}
.app-header{
  background: #e54545;
  border-bottom: none;
}
.navbar-nav .nav-link.active{
  color: #ffffff;
  font-weight: bold;
}
.navbar-nav .nav-link{
  color: #ffffff;
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus{
  color: #952f2f;
}
.app-footer{
  background: #e54545;
  border-top: none;
  color: #fff;
}
.breadcrumb{
  background: #2a2a2a;
  border-bottom: none;
}
.breadcrumb-item.active{
  color: #ffffff;
}
.breadcrumb-item + .breadcrumb-item::before{
  color: #ffffff;
}
.breadcrumb-item a {
  font-weight: bold;
}
.sidebar{
  background: #e54545;
}
.sidebar .nav-link{
  color: #ffffff !important;
}
.sidebar .nav-link.active{
  background: #952f2f;
  color: #ffffff !important;
}
.sidebar .nav-link:hover{
  background: #952f2f;
  color: $white-color !important;
}
.sidebar .nav-link .nav-icon{
  color: #fff;
}
.sidebar .nav-link.active .nav-icon{
  color: #ffffff;
}
.sidebar-minimized .sidebar .nav-item:hover{
  background: $secondary-color;
}
.sidebar-minimized .sidebar .nav-link:hover{
  background: $secondary-color;
}
.badge-info{
  background: #e54545;
}
.video_pic img{
  width: 150px;
  height: 150px;
}
.edit_video_pic img{
  width: 50%;
  height: auto;
}
.inlineDim {
  position: fixed;
  display: inline-block;
  padding: 50px;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  right: 0;
  background: rgba(255,255,255, .8);
}
.inlineDim .content{ background: url("./assets/images/loading.gif") 100%; width: 292px; height: 250px; margin: 20% auto;}
.upload_profile_pic{
	background: #191919;
	text-align: center;
	padding: 40px;
	margin: 20px auto;
  opacity: 0.8;
}
.upload_profile_pic h5{
	color: #b7b7b7;
  font-size: 16px;
  font-family: 'Conv_Arvo-Bold';
  letter-spacing: 5px;
	margin-bottom: 50px;
	padding-top: 20px;
}
.upload_profile_pic h6{
	color: #b7b7b7;
  font-size: 16px;
  font-family: 'Conv_Arvo-Italic';
  letter-spacing: 1px;
	padding-top: 50px;
  margin: 0;
}
.upload_profile_pic .ui.multiple.dropdown>.label{
	margin: 0.7rem .28571429rem .14285714rem 0 !important;
	float: left;
}
.upload_profile_pic .ui.label {
    background-color: #d8ab4c !important;
		font-family: 'Conv_Arvo-Bold';
		font-size: 13px;
    color: #fff !important;
    border-radius: 50px !important;
    border: none !important;
}
.upload_profile_pic .ui.label>.delete.icon {
  color: #fff !important;
	opacity: 1;
}
.input_field{
  border-radius: 0px;
  background: #ffffff;
  opacity: 1;
  height: 60px;
	font-family: 'Conv_Campton-MediumItalic';
  margin-bottom: 20px;
	text-align: left;
	text-indent: 20px;
}
.bg-primary{
  background: $primary-color !important;
}
.btn-primary{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white-color !important;
}
.btn-primary:not(:disabled):not(.disabled).active{
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
  color: $white-color !important;
}
.ant-btn{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white-color !important;
}
.ant-btn-primary{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white-color !important;
}
.ant-modal-header{
  background-color: $primary-color !important;
  .ant-modal-title{
    color: $white-color !important;
  }
}
.ant-modal-footer{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.submit_btn {
  width: 100%;
  text-align: center;
}
.page_bg{
	//background: url(../assets/images/page-bg.jpg) no-repeat;
	background-size:100% 100%;
	width: 100%;
	height: auto;
	padding-top: 50px;
  padding-bottom: 50px;
	.signup_form{
		width: 620px;
		max-width: 95%;
		margin: 0 auto;
		background: #000000;
		opacity: 0.7;
		padding: 50px;
		text-align: center;
		h1{
			color: #eaeaea;
			font-family: 'Conv_Campton-Light';
			font-size: 48px;
			padding-bottom: 50px;
		}
		.input_field{
	    border-radius: 0px;
	    background: #3d393e;
	    opacity: 1;
	    height: 60px;
	    color: #eaeaea;
			font-family: 'Conv_Campton-MediumItalic';
	    margin-bottom: 40px;
			text-align: left;
    	text-indent: 20px;
		}
		.already_signup{
			color: #eaeaea;
			font-family: 'Conv_Campton-Light';
			font-size: 16px;
			padding-top: 40px;
			span{
				color: $secondary-color;
			}
		}
	}
}
.delete_popup {
    font-family: 'Conv_Arvo-Bold';
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}
.delete_popup button{
	background: $primary-color;
	border: none;
	color: #ffffff !important;
	font-family: 'Conv_Campton-SemiBold';
	font-size: 16px;
	letter-spacing: 5px;
	border-radius: 0px;
	padding: 7px 20px;
  margin-right: 20px;
}
.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.dropdown {
            .dropdown-menu {
                min-width: 15rem;
                padding: 15px 15px;
                margin: 0;
                top: 115%;
                background-color: $white-color;
                box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
                border: none;
                border-radius: 10px;
                display: inline-block;
                opacity: 0;
                visibility: hidden;
                transition-duration: 0.4s;
                margin-right: -10px;
                margin-top: -10px;
                .dropdown-item {
                    padding: 0;
                    margin: 15px 0;
                    background: transparent;
                    color: #333333;

                    &:hover, &.active {
                        color: $theme-color;
                    }
                }
            }
            &:hover {
                .dropdown-menu {
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
.add_new_media{
  margin-top: 30px;
}

.dash_page_title{
	position: relative;
	padding-bottom: 20px;
}
.dash_page_title h2{
	color: $theme-color;
	font-size: 30px;
	font-family: 'Conv_Arvo-Regular';
	letter-spacing: 3px;
	padding-top: 30px;
	display: inline-block;
}
.dash_page_title h4{
	color: $theme-color;
	font-size: 16px;
	font-family: 'Conv_Arvo-Regular';
	letter-spacing: 0px;
	margin-top: 0px;
}
.dash_page_title span.club_name{
	font-family: 'Conv_Arvo-Bold';
	padding-right: 20px;
}
.dash_page_title h3{
	color: $theme-color;
	font-size: 24px;
	font-family: 'Conv_Arvo-Regular';
	letter-spacing: 1px;
	padding-top: 75px;
}
form#contactForm input.ant-calendar-picker-input.ant-input{
  line-height: 1.5;
  border-radius: 0px;
  background: #ffffff;
  opacity: 1;
  height: 60px;
  font-family: 'Conv_Campton-MediumItalic';
  margin-bottom: 40px;
  text-align: left;
  text-indent: 20px;
  color: #5c6873 !important;
}
form#contactForm .ant-calendar-picker-icon{
  display: none;
}
.ant-select-selection--single{
  height: 60px !important;
}
.ant-select-selection__rendered{
  line-height: 60px !important;
}
.MuiTableCell-body{
  text-align: center;
}
label{
  color: #191919;
}
form#contactForm .ant-calendar-picker{
  width: 100%;
}
form#contactForm input.ant-calendar-picker-input.ant-input{
  width: 100%;
  margin-bottom: 20px;
}
form#contactForm .ant-time-picker{
  width: 100%;
}
form#contactForm .ant-time-picker-input{
  height: 60px;
}
.form-check-input.is-valid ~ .form-check-label {
    color: #5c6873;
}
form#contactForm .form-check-label{
  font-size: 15px;
}
